<template>

    <!-- <b-modal no-close-on-backdrop no-close-on-esc ... -->
    <b-modal id="modal_search_result" size="xl"  :hide-header="true" hide-footer>
        <div class="close-button float-right"><button size="lg" @click="close()" title="Fechar janela">
                <i class="text-danger fa fa-times fa-lg"></i>
            </button></div>
            <div v-if="searchedQuery != ''" class="d-flex card h-100 py-1" style="max-height: 100px;overflow-y: auto;">
                <h5>Resultado da consulta por: <strong>"{{searchedQuery}}"</strong></h5>
            </div>
            <div v-else class="d-flex card h-100 py-1">
                <h5><strong>Resultado da consulta</strong></h5>
            </div>
        <div class="row">
            <div class="col-md col-6 order-md-1 order-2 text-center text-md-left">
                <Button @openFilters="openFilters" action="openFilters" type="fill" name="Pesquisar" title="Pesquisa avançada" :noMargin="true"></Button>
                <Button @callOpenModalActiveFilters="callOpenModalActiveFilters" action="callOpenModalActiveFilters" type="fill" name="Filtros ativos" title="Filtros ativos" :noMargin="true"></Button>
            </div>
            <div class="col-md-9 col-12 text-md-right d-none d-md-block order-md-2">
                <span class="mr-3"><b>{{total}}</b> registros</span>
                <ButtonExport type="row" :query="query" :selected="selected" name="Exportar tabela" />
                <ButtonExport type="reg" :query="query" :selected="selected" name="Exportar registro"/>
                <ButtonExport v-if="totalFichasPublicadas>0" type="pdf" :query="query" :selected="selected" name="Baixar fichas atuais (zip)"/>
                <Button @comoCitar="comoCitar" action="comoCitar" type="fill" name="Como citar" :noMargin="true"/>
            </div>

<!-- RESPONSIVE MODE FOR FILTERS -->
            <div class="col-6 mb-2 text-center d-block d-md-none order-1">
                <span class="mr-3"><b>{{total}}</b> registros</span>
            </div>
            <div class="col-6 text-center my-3 d-block d-md-none order-3">
                <ButtonExport type="row" :query="query" :selected="selected" name="Exportar tabela" />
            </div>
            <div class="col-6 text-center my-3 d-block d-md-none order-4">
                <ButtonExport type="reg" :query="query" :selected="selected" name="Exportar registro"/>
            </div>
            <div class="col-6 text-center d-block d-md-none order-5">
                <ButtonExport type="pdf" :query="query" :selected="selected" name="Baixar fichas atuais (zip)"/>
            </div>
            <div class="col-6 text-center d-block d-md-none order-6">
                <Button @comoCitar="comoCitar" action="comoCitar" type="fill" name="Como citar" :noMargin="true"/>
            </div>
        </div>
        <TableSR class="table-responsive" @totalFichasPublicadas="totalFichasPublicadas= $event" @total="total = $event" :endpoint="endpoint" @tableSearchResultSelected="setSelected" />
    </b-modal>
</template>

<script>
import Button from '@/components/forms/Button.vue'
import ButtonExport from '@/components/searchComponents/ButtonExport.vue'
import barramento from '@/barramento'
import TableSR from '@/components/TableSearchResult.vue'
import selectOptionsStorage from '@/selectOptionsStorage_mixins.js'
import toggleFilterStorage from '@/toggleFilterStorage_mixins.js'

export default {
    components: {
        TableSR,
        ButtonExport,
        Button

    },
    data() {
        return {
            searchEndpoint: '/search',
            endpoint:'',
            total: 0,
            messages: [],
            items: [],
            filters: {},
            selected: '',
            searchedQuery: '',
            query: '',
            totalFichasPublicadas:0
        }
    },
    mixins:[selectOptionsStorage,toggleFilterStorage],
    created() {
        barramento.$on('openModalR', (params = null) => {
            //debugger; // eslint-disable-line no-debugger
            this.endpoint = ''
            this.searchedQuery = ''
            this.total = 0
            //filters from graphics
            if (params != null) {
                barramento.$emit("buttonLimparFiltrosClear")
                this.endpoint = this.searchEndpoint + params.query
                this.searchedQuery = params.filter
                this.query = params.query
                let filterName = Object.keys(params.items)[0]
                let filterId = params.items[filterName][0]
                let filterLabel = params.filter
                this.toggleItem('activeFilterButton',filterName)

                // Se filterId não existir tenta pegar do params
                if(undefined == filterId){
                    if(params.items && params.items['grupoIds']){
                        filterId = params.items['grupoIds']
                    }
                }
                this.addTag(filterName,{'id':filterId,'name':filterLabel})
                /**
                 * Comentado para evitar limprar os filtros quando for uma busca oriunda do clique no
                 * icone das especies em categoria de ameaça
                 */
                //this.addTag('filterFromGraphic',"true")
            } else { //filters from advanced search
                this.query = this.makeQuery()
                this.setSearchQuery()
                this.endpoint = this.searchEndpoint + this.query
            }
            this.$bvModal.hide('modal_advanced_search')
            this.$bvModal.show('modal_search_result')
        })
    },
    methods: {
        setSelected (selected) {
            this.selected = ''
            for(var item in selected){
                if(this.selected == ''){
                    this.selected = selected[item]
                } else {
                    this.selected = this.selected + ',' + selected[item]
                }
            }
        },
        setSearchQuery() {
            if (this.getTagsFromKey('q') != false) {
                var word = this.getTagsFromKey('q')
                this.searchedQuery = word[0]
            }
        },
        makeQuery() {
            this.selected = ''
            let query = '/?'
            let word = this.getItemsFromKey("q")
            if (word != false) {
                word = JSON.parse(word);
                query += 'q=' + word[0]
            }

            let activeFilters = this.getItemsFromKey("activeFilterButton")
            if (activeFilters != false) {
                activeFilters = activeFilters.split(",")
                let vm = this
                activeFilters.forEach(function(filter) {
                    let filterItems = vm.getTagsFromKey(filter)
                    if (filterItems != false) {
                        query += "&"+filter+"="
                        filterItems.forEach(function(item){
                            if (item.id != undefined) {
                                if(query[query.length-1] !== "="){
                                    query += ','+item.id
                                }else{
                                    query += item.id
                                }
                            } else if (item.extraFilters){
                                for (const [key, value] of Object.entries(item.extraFilters)) {
                                    query += "&"+key+"="+value
                                }
                            } else {
                                if(item instanceof Object){
                                    // Para evitar erro enviando [object] como parametro
                                    var aGrupos = []
                                    aGrupos['Mamiferos']                    = 1262
                                    aGrupos['Mamíferos Aquáticos']          = 1263
                                    aGrupos['Aves']                         = 1258
                                    aGrupos['Répteis']                      = 1266
                                    aGrupos['Anfíbios']                     = 1257
                                    aGrupos['Peixes Continentais']          = 1264
                                    aGrupos['Peixes Marinhos']              = 1265
                                    aGrupos['Invertebrados Terrestres']     = 1261
                                    aGrupos['Invertebrados de Água Doce']   = 1259
                                    aGrupos['Invertebrados Marinhos']       = 1260

                                    query += aGrupos[item.name] + '&threatened=s'
                                    //Limpa os filtros para evitar conflito pois a busca da imagem é combinada com a categoria
                                    //barramento.$emit("buttonLimparFiltrosClear")
                                }else{
                                    query += item
                                }
                            }
                        })
                    }
                }.bind(query))
            }

            // alterar ponto-e-virgula para virgula para evitar erro na pesquisa
            query = query.replace(/;/gm,',')
            return query;
        },
        close(){
             this.$bvModal.hide('modal_search_result')
             this.total = 0
             this.messages = []
             this.items = []
             this.filters = {}
             this.selected = ''
        },

        openFilters() {
            this.$parent.$emit('openModalAdvancedSearch')
        },

        callOpenModalActiveFilters(){
            barramento.$emit('openModalActiveFilters');
        },

        comoCitar() {
            barramento.$emit('modal_como_citar')
        }
    }
}
</script>

<style lang="scss">
    @import '@/web/scss/tables.scss';
    .result-infos {
        p {
            margin: auto 10px;
        }
    }
    .categ-result {
        width: 35%;
    }
    #modal_search_result .modal-body {
        box-shadow: 23px 20px 31px -10px rgba(0,0,0,0.72);
        -webkit-box-shadow: 23px 20px 31px -10px rgba(0,0,0,0.72);
        -moz-box-shadow: 23px 20px 31px -10px rgba(0,0,0,0.72);
    }
    #modal_search_result .card {
        margin: 15px;
    }
    #modal_search_result .close-button {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1000;
        margin-top: 15px;
        margin-right: 15px;

    }
    #modal_search_result .close-button button {
        background-color: transparent;
        border: none;
    }

</style>
