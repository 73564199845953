<template>
    <Button @export="exportData" action="export" type="fill" :name="name" :noMargin="true"/>
</template>
<script>
import Button from '@/components/forms/Button.vue'
import barramento from '@/barramento'
export default {
    props:['name','type','query', 'selected'],
    components:{Button},
    data() {
        return {
            endpoint: '/search',
        }
    },
    methods: {
        exportData() {
            let s = this.query + '&export='+this.type
            if(this.selected.length > 0){
                //s = '?'+ s + '&selectedIds=' + this.selected
                s = s + '&selectedIds=' + this.selected
            }

            barramento.$emit('modal_export', this.endpoint + s)

        },
    }
}
</script>
