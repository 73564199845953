<template>
    <div>
        <ul class="d-flex col-12 justify-content-center">
            <li class="pagination mx-1" v-for="(element, index) in elements.flat()" :key="index">
                <b-button disabled v-if="element == '...'">{{element}}</b-button>
                <b-button href="#tableResult"  @click="currentPage = element" :variant="(currentPage==element) ? 'primary' :'outline-primary'" v-else>{{element}}</b-button>
            </li>
        </ul>
     </div>
</template>

<script>
// import barramento from '@/barramento'
export default {
    props: ['totalPages', 'mobile'],
    data() {
        return {
            firstPage: 0,
            lastPage: 0,
            currentPage: 1,
            elements: []
        }
    },
    created() {
        this.getElements()
    },
    watch: {
        currentPage(){
            this.getElements()
            this.$parent.$emit('changePage', this.currentPage)
        }
    },
    methods: {
        getElements(){
            var pages = []
            if(this.mobile){
                if(this.currentPage < 4) {
                    pages = Array.from(Array(5).keys()).slice(1)
                    this.firstPage = 1
                    this.lastPage = 4
                } else {
                    pages = Array.from(Array(this.totalPages).keys()).slice(this.currentPage-1, this.currentPage+2)
                    this.firstPage = this.currentPage - 1
                    this.lastPage = this.currentPage + 1
                }
                if(this.firstPage <= 2) {
                    if(this.totalPages > 7) {
                        this.elements = [Array.from(Array(5).keys()).slice(1), '...', this.totalPages-1, this.totalPages]
                    } else {
                        this.elements = Array.from(Array(this.totalPages + 1).keys()).slice(1)
                    }
                } else if (this.lastPage >= this.totalPages -1){
                    if(this.totalPages > 7) {
                        this.elements = [1,2, '...', Array.from(Array(this.totalPages + 1).keys()).slice(1).slice(-4)]
                    } else {
                        this.elements = Array.from(Array(this.totalPages + 1).keys()).slice(1)
                    }
                } else {
                    if(this.totalPages > 7) {
                        this.elements = [1, '...', pages, '...', this.totalPages]
                    } else {
                        this.elements = Array.from(Array(this.totalPages + 1).keys()).slice(1)
                    }
                }

            } else {
                if(this.currentPage < 4) {
                    pages = Array.from(Array(8).keys()).slice(1)
                    this.firstPage = 1
                    this.lastPage = 7
                } else {
                    pages = Array.from(Array(this.totalPages).keys()).slice(this.currentPage-2, this.currentPage+3)
                    this.firstPage = this.currentPage - 3
                    this.lastPage = this.currentPage + 3
                }
                if(this.firstPage <= 3) {
                    if(this.totalPages > 10) {
                        this.elements = [Array.from(Array(8).keys()).slice(1), '...', this.totalPages-1, this.totalPages]
                    } else {
                        this.elements = Array.from(Array(this.totalPages + 1).keys()).slice(1)
                    }
                } else if (this.lastPage >= this.totalPages -1){
                    if(this.totalPages > 10) {
                        this.elements = [1,2, '...', Array.from(Array(this.totalPages + 1).keys()).slice(1).slice(-8)]
                    } else {
                        this.elements = Array.from(Array(this.totalPages + 1).keys()).slice(1)
                    }
                } else {
                    if(this.totalPages > 13) {
                        this.elements = [1, 2, '...', pages, '...', this.totalPages-1, this.totalPages]
                    } else {
                        this.elements = Array.from(Array(this.totalPages + 1).keys()).slice(1)
                    }
                }
            }
        },
        currentPageClick(e){
            this.currentPage = e
        }
    }

}
</script>

<style>

</style>